import 'core-js/stable';
import React from 'react';
import Head from 'next/head';
import getConfig from 'next/config';
import PropTypes from 'prop-types';

import '../lib/pollyfills/findLastIndex';
import { customCSS, resetStyles } from '../styles/config';
import { bodyFont } from '../styles/config/fonts';
import theme from '../styles/config/theme';
import { PORTAL_ID } from '../lib/constants';

const App = ({ Component, pageProps }) => {
  const { publicRuntimeConfig } = getConfig();
  const releaseVersion = publicRuntimeConfig.ENVIRONMENT.ReleaseVersion;

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex" />
        <meta httpEquiv="x-ua-compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content={theme.COLOR_LAKE_GREEN_DARK} />
        <title>Caravan and Motorhome Club</title>
        <meta name="description" content="A page's description, usually one or two sentences." />

        {/* Standard Favicon */}
        <link rel="icon" id="favicon" type="image/x-icon" href={`/static/images/meta/favicon.ico?v=${releaseVersion}`} />
        {/* For Apple devices - Android and other platforms will also pick it up */}
        <link rel="apple-touch-icon" href={`/static/images/meta/apple-touch-icon.png?v=${releaseVersion}`} />

        <meta name="apple-mobile-web-app-title" content="Caravan and Motorhome Club" />
        <meta name="application-name" content="Caravan and Motorhome Club" />
        <meta name="msapplication-TileColor" content={theme.COLOR_LAKE_GREEN_DARK} />
        <meta name="theme-color" content="#ffffff" />

        <meta name="msapplication-config" content={`/static/browserconfig.xml?v=${releaseVersion}`} />
        <link rel="manifest" href={`/static/site.webmanifest?v=${releaseVersion}`} />

        {/* cdn for modernizr, if you haven't included it already */}
        <script src="https://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.3/modernizr.min.js" />
        {/* polyfiller file to detect and load polyfills */}
        <script src="https://cdnjs.cloudflare.com/ajax/libs/webshim/1.16.0/dev/polyfiller.js" />

        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />

        <script
          dangerouslySetInnerHTML={{
            __html: `
            try{
              window.__timings = [{ type: 'IBE', start: new Date().getTime(), metrics: [], completed: false}];
              var lastTimingInstance =  window.__timings[window.__timings.length - 1];
              var lastTimingMetricInstance = lastTimingInstance.start;
              console.info('Timings', lastTimingInstance.type, '- UI Load Start', new Date(lastTimingInstance.start).toLocaleString().substring(12));
            }catch(e){}
            `,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `
            dataLayer = []
            `,
          }}
        />

        {publicRuntimeConfig.GTM_TOKEN !== '' &&
          <script
            dangerouslySetInnerHTML={{
              __html: ` (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${publicRuntimeConfig.GTM_TOKEN}');`,
            }}
          />
        }

        {publicRuntimeConfig.GA_TOKEN !== '' &&
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.GA_TOKEN}`} />
        }

        {publicRuntimeConfig.GA_TOKEN !== '' &&
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${publicRuntimeConfig.GA_TOKEN}');
              `,
            }}
          />
        }

        <style dangerouslySetInnerHTML={{ __html: resetStyles(customCSS, bodyFont) }} />
      </Head>
      <Component {...pageProps} />
      <div id={PORTAL_ID} />
    </>
  );
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.shape({
    serverSideCookie: PropTypes.string,
  }),
};

App.defaultProps = {
  pageProps: undefined,
};

export default App;
