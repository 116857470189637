import theme from './theme';

export default `
  .customSelect {
    padding-right: 2rem;
    margin-left: 0.75rem;
    min-width: 6rem;
    height: ${theme.FIELD_SIZE_MEDIUM};
    padding: 0 1rem;
    appearance: none;
    background-color: ${theme.COLOR_WHITE};
    background-image: url('../../static/images/icons/SelectArrow.svg');
    background-position: right 1rem center;
    background-size: 0.9375rem;
    border: 1px solid ${theme.COLOR_GRAY_LIGHT};
    border-radius: 1px;
    color: ${theme.COLOR_TEXT_BODY};
    font-size: 1rem;
    font-family: ${theme.TEXT_BODY_FONT};
  }

  .customSelect.bordered {
    border: 1px solid ${theme.COLOR_GRAY_DARK};
  }

  .customSelect.formError {
    border: 1px solid ${theme.COLOR_DANGER};
  }

  .customSelect[data-error="true"] {
    border-color: ${theme.COLOR_DANGER};
  }

  .customSelect:first-of-type {
    margin-left: 0;
  }

  .customSelect:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${theme.COLOR_LAKE_GREEN_DARK};
  }

  .customSelect:last-of-type {
    margin-right: 0;
  }

  .customSelect:disabled {
    background-color: ${theme.COLOR_GRAY_LIGHTEST};
    color: ${theme.COLOR_TEXT_MUTED};
    pointer-events: none;
  }

  .customSelect.customSelectFlex {
    flex: 1;
  }

  .customSelect.customSelectFlex.customSelectSmall {
    width: 90px;
    min-width: unset;
    max-width: 90px;
  }

  @media only screen and (min-width: ${theme.DESKTOP}px){
    .customSelect {
      font-size: 0.875rem;
    }
  }

  @media only screen and (max-width: ${theme.MOBILE_LARGE}px){
    .customSelect, .customSelect.customSelectFlex.customSelectSmall {
      margin-left: 0;
      margin-top: 0.75rem;
      width: 100%;
    }
  }

  select::-ms-expand {
    display: none;
  }
`;
